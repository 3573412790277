import React, { useContext, useState, useEffect } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import SopotakContext from "../../../store/sopotak-context";
import config from "../../../api/api-helper";
import { getLocations, getRoutes } from "../../../api/api-helper";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import "./SearchWidget.css";
import {
  Container,
  FloatingLabel,
  Row,
  Col,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles.css";
import MapComponent from "../../map/MapComponent";

const SearchWidget = () => {
  const ctx = useContext(SopotakContext);

  const navigate = useNavigate();

  const maxPax = 7;

  const [locations, setLocations] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [ready, setReady] = useState(false);
  const [validated, setValidated] = useState(false);

  const [fromCoordinates, setFromCoordinates] = useState(null);
  const [toCoordinates, setToCoordinates] = useState(null);

  const fetchOffers = (body) => {
    ctx.setOffersData(null);
    setIsLoading(true);
    const {
      type,
      departureLocation,
      departureTime,
      arrivalLocation,
      returnTime,
      numberOfPersons,
      numberOfChildren,
      numberOfBikes,
      durationInMinutes,
    } = body;

    let url =
      `${config.apiUrl}/api/sopotak/v1/offers?` +
      `type=${encodeURIComponent(type)}` +
      `&departureLocation=${encodeURIComponent(departureLocation)}` +
      `&departureTime=${encodeURIComponent(
        new Date(departureTime).toISOString()
      )}` +
      `&arrivalLocation=${encodeURIComponent(arrivalLocation)}` +
      `&numberOfPersons=${encodeURIComponent(numberOfPersons)}` +
      `&numberOfChildren=${encodeURIComponent(numberOfChildren)}` +
      `&numberOfBikes=${encodeURIComponent(numberOfBikes)}`;

    if (type === "TIME_BASED") {
      url += `&durationInMinutes=${encodeURIComponent(durationInMinutes)}`;
    }

    if (type === "RETURN") {
      url += `&returnTime=${encodeURIComponent(
        new Date(returnTime).toISOString()
      )}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        ctx.setOffersData(data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching offers:", error);
      });
  };

  useEffect(() => {
    let mounted = true;
    getLocations().then((locationsResponse) => {
      if (mounted) {
        const parsedLocations = locationsResponse.locations.map((location) => ({
          lat: parseFloat(location.latitude),
          lng: parseFloat(location.longitude),
          value: location.value,
          label: location.label,
        }));
        setLocations(parsedLocations);
      }
    });
    getRoutes().then((routesResponse) => {
      if (mounted) {
        setRoutes(routesResponse.routes);
      }
    });
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (ready) return;
    const {
      tripType,
      from,
      to,
      fromDate,
      toDate,
      persons,
      children,
      bikes,
      duration,
    } = ctx;
    const searchTripType = searchParams.get("type") ?? tripType;
    const searchFrom = searchParams.get("depLoc") ?? from;
    const searchTo = searchParams.get("arrLoc") ?? to;
    const searchFromDate = searchParams.get("depTime") ?? fromDate;
    const searchToDate = searchParams.get("returnTime") ?? toDate;
    const searchPersons = parseInt(searchParams.get("person") ?? persons);
    const searchChildren = parseInt(searchParams.get("children") ?? children);
    const searchBikes = parseInt(searchParams.get("bikes") ?? bikes);
    const searchDuration =
      validateDuration(searchParams.get("dur")) ?? duration;

    const normalizedFromDate = new Date(searchFromDate).setHours(12, 0, 0, 0);
    const normalizedToday = new Date().setHours(12, 0, 0, 0);
    const finalFromDate =
      normalizedFromDate < normalizedToday
        ? normalizedToday
        : normalizedFromDate;
    const finalToDate =
      new Date(searchFromDate) > new Date(searchToDate)
        ? searchFromDate
        : searchToDate;

    ctx.setTripType(searchTripType);
    ctx.setFromDestination(searchFrom);
    ctx.setToDestination(
      searchTripType === "TIME_BASED" ? searchFrom : searchTo
    );
    ctx.setFromDate(finalFromDate);
    ctx.setToDate(finalToDate);
    ctx.setPersons(searchPersons);
    ctx.setChildren(searchChildren);
    ctx.setBikes(searchBikes);
    ctx.setDuration(searchDuration);

    const body = {
      type: searchTripType,
      departureLocation: searchFrom,
      departureTime: finalFromDate,
      arrivalLocation: searchTripType === "TIME_BASED" ? searchFrom : searchTo,
      numberOfPersons: searchPersons,
      numberOfChildren: searchChildren,
      numberOfBikes: searchBikes,
      durationInMinutes: searchDuration,
      returnTime: finalToDate,
    };

    fetchOffers(body);
    setReady(true);
  }, [ready, searchParams, ctx]);

  useEffect(() => {
    const fromLocation = locations.find(
      (location) => location.value === ctx.from
    );
    const toLocation = locations.find((location) => location.value === ctx.to);

    if (fromLocation) {
      setFromCoordinates({ lat: fromLocation.lat, lng: fromLocation.lng });
    } else {
      setFromCoordinates(null);
    }

    if (toLocation) {
      setToCoordinates({ lat: toLocation.lat, lng: toLocation.lng });
    } else {
      setToCoordinates(null);
    }
  }, [ctx.from, ctx.to, locations]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const body = {
        type: ctx.tripType,
        departureLocation: ctx.from,
        departureTime: ctx.fromDate,
        arrivalLocation: ctx.tripType === "TIME_BASED" ? ctx.from : ctx.to,
        numberOfPersons: ctx.persons,
        numberOfChildren: ctx.children,
        numberOfBikes: ctx.bikes,
        durationInMinutes: ctx.duration,
        returnTime: ctx.toDate,
      };
      fetchOffers(body);

      const params = {
        type: ctx.tripType,
        depLoc: ctx.from,
        depTime: new Date(ctx.fromDate).toISOString(),
        person: ctx.persons,
        children: ctx.children,
        bikes: ctx.bikes,
      };

      if (ctx.tripType === "RETURN") {
        params.arrLoc = ctx.to;
        params.returnTime = new Date(ctx.toDate).toISOString();
      } else if (ctx.tripType === "ONEWAY") {
        params.arrLoc = ctx.to;
      } else if (ctx.tripType === "TIME_BASED") {
        params.arrLoc = ctx.from;
        params.dur = ctx.duration;
      }

      const queryString = Object.entries(params)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");

      navigate(`/order?${queryString}`);
    }
    setValidated(true);
  };

  function validateDuration(duration) {
    const parsedDuration = parseInt(duration);

    if (
      Number.isNaN(parsedDuration) ||
      parsedDuration % 60 !== 0 ||
      parsedDuration > 360 ||
      parsedDuration === 0
    ) {
      return null;
    }

    return parsedDuration;
  }

  const clearOffers = () => {
    ctx.setOffersData(null);
  };

  const fromDestinationHandler = (event) => {
    if (ctx.tripType === "TIME_BASED") {
      ctx.setToDestination(event.target.value);
    }
    ctx.setFromDestination(event.target.value);
    clearOffers();
  };

  const toDestinationHandler = (event) => {
    ctx.setToDestination(event.target.value);
    clearOffers();
  };

  const durationHandler = (event) => {
    ctx.setDuration(parseInt(event.target.value));
    clearOffers();
  };

  const personsHandler = (event) => {
    ctx.setPersons(parseInt(event.target.value));
    clearOffers();
  };

  const childrenHandler = (event) => {
    ctx.setChildren(parseInt(event.target.value));
    clearOffers();
  };

  const bikesHandler = (event) => {
    ctx.setBikes(parseInt(event.target.value));
    clearOffers();
  };

  const fromDateHandler = (e) => {
    const [year, month, day] = e.target.value.split("-");
    const fromDate = new Date(ctx.fromDate);
    fromDate.setFullYear(parseInt(year), parseInt(month) - 1, parseInt(day));
    ctx.setFromDate(fromDate);

    if (new Date(ctx.toDate) < fromDate) {
      ctx.setToDate(fromDate);
    }
    clearOffers();
  };

  const toDateHandler = (e) => {
    const [year, month, day] = e.target.value.split("-");
    const date = new Date(ctx.toDate);
    date.setFullYear(parseInt(year), parseInt(month) - 1, parseInt(day));
    ctx.setToDate(date);
    clearOffers();
  };

  const handleTripType = (value) => {
    if (value !== "TIME_BASED" && ctx.from === ctx.to) {
      ctx.setToDestination("");
    }
    ctx.setTripType(value);

    clearOffers();
  };

  const convertDate = (date) => {
    const yyyy = date.getFullYear().toString();
    const mm = (date.getMonth() + 1).toString().padStart(2, "0");
    const dd = date.getDate().toString().padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const checkIfRouteExists = (departureLocation, arrivalLocation) => {
    return (
      routes.some(
        (route) =>
          route.departureLocation === departureLocation &&
          route.arrivalLocation === arrivalLocation
      ) ||
      !departureLocation ||
      !arrivalLocation
    );
  };

  const generateOptions = (
    currentValue,
    otherValuesSum,
    maxValue,
    includeZero = false
  ) => {
    const options = Array.from({ length: maxValue }, (_, i) => i + 1);
    if (includeZero) options.unshift(0);
    return options.map((value) => (
      <option
        key={value}
        value={value}
        disabled={value + otherValuesSum > maxPax}
      >
        {value}
      </option>
    ));
  };

  const selectedLocations = [];
  if (fromCoordinates) selectedLocations.push(fromCoordinates);
  if (toCoordinates && ctx.tripType !== "TIME_BASED")
    selectedLocations.push(toCoordinates);

  return (
    <div className="form-wrapper">
      <Form noValidate onSubmit={handleSubmit}>
        <Container fluid>
          <Row>
            <Col className="gx-2 mb-2">
              <ToggleButtonGroup
                type="radio"
                name="option"
                onChange={handleTripType}
                value={ctx.tripType}
                defaultValue="RETURN"
                className="w-100"
              >
                <ToggleButton variant="outline-dark" value="RETURN" id="radio1">
                  W dwie strony
                </ToggleButton>
                <ToggleButton variant="outline-dark" value="ONEWAY" id="radio2">
                  W jedną stronę
                </ToggleButton>
                <ToggleButton
                  variant="outline-dark"
                  value="TIME_BASED"
                  id="radio3"
                >
                  Kurs czasowy
                </ToggleButton>
              </ToggleButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col className="col-6 gx-2 mb-2">
              <Form.Group>
                <FloatingLabel controlId="departurePort" label="Skąd">
                  <Form.Select
                    className="form-control"
                    size="lg"
                    onChange={fromDestinationHandler}
                    value={ctx.from}
                    required
                    isInvalid={validated && ctx.from.length < 1}
                  >
                    <option></option>
                    {locations.map((item) => (
                      <option
                        key={item.value}
                        value={item.value}
                        disabled={!checkIfRouteExists(item.value, ctx.to)}
                      >
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Wypełnij pole.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
            {(ctx.tripType === "RETURN" || ctx.tripType === "ONEWAY") && (
              <Col className="col-6 gx-2 mb-2">
                <Form.Group>
                  <FloatingLabel controlId="arrivalPort" label="Dokąd">
                    <Form.Select
                      className="form-control"
                      size="lg"
                      onChange={toDestinationHandler}
                      value={ctx.to}
                      placeholder="Wybierz"
                      required
                      isInvalid={validated && ctx.to.length < 1}
                    >
                      <option></option>
                      {locations.map((item) => (
                        <option
                          key={item.value}
                          value={item.value}
                          disabled={!checkIfRouteExists(ctx.from, item.value)}
                        >
                          {item.label}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Wypełnij pole.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            )}
            {ctx.tripType === "TIME_BASED" && (
              <Col className="col-6 gx-2 mb-2">
                <Form.Group>
                  <FloatingLabel controlId="duration" label="Czas trwania (h)">
                    <Form.Select
                      className="form-control"
                      size="lg"
                      onChange={durationHandler}
                      value={ctx.duration}
                      required
                      isInvalid={validated && ctx.duration.length < 1}
                    >
                      <option value={60}>1h</option>
                      <option value={120}>2h</option>
                      <option value={180}>3h</option>
                      <option value={240}>4h</option>
                      <option value={300}>5h</option>
                      <option value={360}>6h</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Wypełnij pole.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            )}
          </Row>
          <Row>
            <Col className="col-6 gx-2 mb-2">
              <Form.Group>
                <FloatingLabel controlId="departureDate" label="Tam">
                  <Form.Control
                    size="lg"
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    onChange={fromDateHandler}
                    value={convertDate(new Date(ctx.fromDate))}
                    required
                    isInvalid={validated && ctx.fromDate.length < 1}
                  />
                  <Form.Control.Feedback type="invalid">
                    Wypełnij pole.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
            {ctx.tripType === "RETURN" && (
              <Col className="col-6 gx-2 mb-2">
                <Form.Group>
                  <FloatingLabel controlId="returnDate" label="Z powrotem">
                    <Form.Control
                      size="lg"
                      type="date"
                      min={new Date(ctx.fromDate).toISOString().split("T")[0]}
                      onChange={toDateHandler}
                      value={convertDate(new Date(ctx.toDate))}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Wypełnij pole.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            )}
          </Row>
          <Row>
            <Col xs={12} md={4} className="gx-2 mb-2">
              <Form.Group>
                <FloatingLabel
                  controlId="persons"
                  label="Osoby (powyżej 10 lat)"
                >
                  <Form.Select
                    size="lg"
                    onChange={personsHandler}
                    value={ctx.persons}
                    required
                    isInvalid={validated && ctx.persons < 1}
                  >
                    {generateOptions(ctx.persons, ctx.children + ctx.bikes, maxPax)}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Na pokładzie musi znaleźć się conajmniej 1 dorosły.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col xs={12} md={4} className="gx-2 mb-2">
              <Form.Group>
                <FloatingLabel controlId="children" label="Dzieci (do 10 lat)">
                  <Form.Select
                    size="lg"
                    onChange={childrenHandler}
                    value={ctx.children}
                    required
                    isInvalid={validated && ctx.children < 0}
                  >
                    {generateOptions(
                      ctx.children,
                      ctx.persons + ctx.bikes,
                      maxPax - 1,
                      true
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Wypełnij pole.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col xs={12} md={4} className="gx-2 mb-2">
              <Form.Group>
                <FloatingLabel controlId="bikes" label="Rowery / Cargo">
                  <Form.Select
                    size="lg"
                    onChange={bikesHandler}
                    value={ctx.bikes}
                    required
                    isInvalid={validated && ctx.bikes < 0}
                  >
                    {generateOptions(
                      ctx.bikes,
                      ctx.persons + ctx.children,
                      2,
                      true
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Wypełnij pole.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 d-grid gap-2 gx-2">
              <Button
                size="lg"
                type="submit"
                disabled={isLoading}
                className="custom-button"
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    variant="light"
                  />
                ) : (
                  "Szukaj"
                )}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="gx-2 mt-2">
              <MapComponent
                allLocations={locations}
                selectedLocations={selectedLocations}
              />
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

export default SearchWidget;
